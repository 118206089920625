import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout } from 'components';
import { Heading } from 'slices';

import ListArticles from '../slices/ListArticles';

const IS_BROWSER = typeof window !== 'undefined';

export const ArticleCategory = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicArticleCategory } = data;
  const { data: pageData } = prismicArticleCategory;
  const {
    title,
    subtitle,
    description,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
    indexed,
  } = pageData;

  const seo = {
    title: metaTitle?.text,
    desc: metaDesc?.text,
    banner: ogImage?.url,
    schema: schema?.text,
    indexed,
  };

  const headingData = {
    primary: {
      title,
      subtitle,
      description,
    },
  };

  return (
    <Layout location={location} seo={seo}>
      <Heading data={headingData} />
      <ListArticles selectedCategory={pageData.category_name.text} />
    </Layout>
  );
};

export default ArticleCategory;

export const serviceQuery = graphql`
  query ArticleCategyBySlug($uid: String!) {
    prismicArticleCategory(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        category_name {
          text
        }
        title {
          html
          text
        }
        subtitle {
          html
          text
        }
        description {
          html
          text
        }

        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
      }
    }
  }
`;
